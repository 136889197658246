import { FC, SVGProps } from 'react';

const Close: FC<SVGProps<SVGSVGElement>> = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <line
        x1="16.6464"
        y1="16.4712"
        x2="10.5288"
        y2="10.3536"
        stroke="currentColor"
      />
      <line
        y1="-0.5"
        x2="8.65172"
        y2="-0.5"
        transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 10.3145 16.1177)"
        stroke="currentColor"
      />
      <circle cx="13.6562" cy="13.3429" r="12.5" stroke="currentColor" />
    </svg>
  );
};

export default Close;
