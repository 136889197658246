import { FC } from 'react';
import styles from './styles.module.css';

interface ToggleSwitchProps {
  onChange?: (value: boolean) => void;
  value?: boolean;
  label?: string;
  containerStyle?: string;
  labelStyle?: string;
}

export const ToggleSwitch: FC<ToggleSwitchProps> = (
  props: ToggleSwitchProps
) => {
  const {
    onChange,
    value,
    label,
    containerStyle = '',
    labelStyle = '',
  } = props;
  return (
    <div className={styles.outerContainer}>
      {label && (
        <div className={`${styles.labelName} ${labelStyle}`}>{label}</div>
      )}
      <div className={styles.container}>
        <div className={styles.toggleSwitch}>
          <input
            type="checkbox"
            className={styles.checkbox}
            name={'toggle'}
            id={'toggle'}
            checked={value}
            onChange={(e) => onChange && onChange(e.target.checked)}
          />
          <label
            className={`${styles.label} ${containerStyle}`}
            htmlFor={'toggle'}
          >
            <span className={styles.inner} />
            <span className={styles.switch} />
          </label>
        </div>
      </div>
    </div>
  );
};
