import { Priceble } from "./types";

export const getPrice = (priceble: Priceble): number | null => {
  let a, b;

  if (!priceble.quoteAmountIn && !priceble.quoteAmountOut) {
    return 0;
  }

  if (priceble.quoteAmountOut && priceble.tokenAmountIn) {
    // buy
    a = BigInt(priceble.quoteAmountOut);
    b = BigInt(priceble.tokenAmountIn);
  }

  if (priceble.quoteAmountIn && priceble.tokenAmountOut) {
    a = BigInt(priceble.quoteAmountIn);
    b = BigInt(priceble.tokenAmountOut);
  }

  if (!a || !b) {
    return null;
  }

  try {
    return Number((a * 1_000_000_000n) / b) / 1_000_000_000;
  } catch (error) {
    return null;
    // throw error;
  }
};
