import { FC, SVGProps } from 'react';

const Cloud12: FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.18042 8.22719C9.7325 8.11503 10.2232 7.80163 10.5573 7.34783C10.8914 6.89403 11.0451 6.33221 10.9885 5.77143C10.9319 5.21065 10.669 4.69093 10.251 4.31315C9.83289 3.93537 9.28944 3.72648 8.7261 3.72705H8.15366C8.00314 3.14399 7.71025 2.60746 7.3013 2.16563C6.89235 1.7238 6.38013 1.39049 5.81064 1.19564C5.24114 1.00078 4.6322 0.950478 4.03847 1.04924C3.44475 1.14801 2.88483 1.39275 2.40899 1.76149C1.93315 2.13024 1.55628 2.61144 1.31222 3.1619C1.06816 3.71236 0.964555 4.31485 1.0107 4.91526C1.05684 5.51568 1.25129 6.09524 1.57659 6.6019C1.90188 7.10855 2.34785 7.52646 2.87444 7.81808M6.45449 5.54529L4.63721 8.27264H7.36314L5.54585 11"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.18042 8.22719C9.7325 8.11503 10.2232 7.80163 10.5573 7.34783C10.8914 6.89403 11.0451 6.33221 10.9885 5.77143C10.9319 5.21065 10.669 4.69093 10.251 4.31315C9.83289 3.93537 9.28944 3.72648 8.7261 3.72705H8.15366C8.00314 3.14399 7.71025 2.60746 7.3013 2.16563C6.89235 1.7238 6.38013 1.39049 5.81064 1.19564C5.24114 1.00078 4.6322 0.950478 4.03847 1.04924C3.44475 1.14801 2.88483 1.39275 2.40899 1.76149C1.93315 2.13024 1.55628 2.61144 1.31222 3.1619C1.06816 3.71236 0.964555 4.31485 1.0107 4.91526C1.05684 5.51568 1.25129 6.09524 1.57659 6.6019C1.90188 7.10855 2.34785 7.52646 2.87444 7.81808M6.45449 5.54529L4.63721 8.27264H7.36314L5.54585 11"
        stroke="url(#paint0_linear_2028_4142)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2028_4142"
          x1="1.875"
          y1="12.0484"
          x2="12.383"
          y2="10.3053"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.975" stopColor="currentColor" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Cloud12;
