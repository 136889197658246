import { useParams } from 'react-router-dom';
import { createChart, ISeriesApi } from 'lightweight-charts';
import { Avatar } from '../../components/Avatar';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Col } from '../../components/Col';
import { Container } from '../../components/Container';
import { Input } from '../../components/Input';
import { ProgressBar } from '../../components/ProgressBar';
import { Row } from '../../components/Row';
import Telegram from '../../components/svg/Telegram';
import Twitter from '../../components/svg/Twitter';
import styles from './styles.module.css';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TransactionTable } from '../../containers/TransactionTable';
import { fetchOrders, fetchToken } from '../../services/tokenService';
import { Order, TokenItem } from '../../interfaces/token';
import { createCandles } from '../../utils/create-candles';
import { Candle } from '../../utils/types';
import { useDataContext } from '../../providers/data';
import Heart from '../../components/svg/Heart';
import PlusSmall from '../../components/svg/PlusSmall';
import { useWindowSize } from '../../hooks/useWindowSize';
import { BottomTabs } from '../../components/BottomTabs';
import Zap from '../../components/svg/Zap';
import Chart from '../../components/svg/Chart';
import Trade from '../../components/svg/Trade';
import Table from '../../components/svg/Table';
import {buyToken} from "../../services/web3Service";
import {useWallet} from "@solana/wallet-adapter-react";
import {PublicKey} from "@solana/web3.js";

export const Detail = () => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [token, setToken] = useState<TokenItem | null>(null);
  const [orders, setOrders] = useState<Order[]>([]);
  const [series, setSeries] = useState<ISeriesApi<'Candlestick', any> | null>(
    null
  );
  const [prices, setPrices] = useState<Array<Candle> | null>(null);
  const { mint } = useParams<{ mint: string }>();
  const { isLoading, orderSubscribe, orderUnsubscribe } = useDataContext();
  const { width } = useWindowSize();
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (chartRef.current) {
      const chart = createChart(chartRef.current, {
        width: chartRef.current.clientWidth,
        height: chartRef.current.clientHeight,
        timeScale: {
          timeVisible: true,
        },
        autoSize: true,
        layout: {
          background: {
            color: 'transparent',
          },
          textColor: '#fff',
        },
      });
      const series = chart.addCandlestickSeries({
        priceFormat: {
          type: 'price',
          precision: 10,
          minMove: 0.000_000_000_1,
        },
        upColor: '#00FF94',
        downColor: '#FC6868',
      });
      setSeries(series);
    }
    return () => {
      if (chartRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        chartRef.current.innerHTML = '';
      }
    };
  }, [chartRef]);

  useEffect(() => {
    if (!series || !prices?.length) {
      return;
    }

    series.setData(prices);
  }, [prices, series]);

  useEffect(() => {
    if (!series || !orders?.length) {
      return; // loading
    }

    console.log({ orders });

    const prices = createCandles(orders, {
      duration: 60,
    });

    setPrices(prices);
  }, [series, orders]);

  useEffect(() => {
    console.log({ mint, isLoading });

    const fetchData = async () => {
      const data = await fetchToken(mint || '');
      const orderData = await fetchOrders(mint || '');
      setToken(data);
      setOrders(orderData);
    };
    if (!mint || isLoading) {
      return;
    }
    fetchData();

    orderSubscribe(mint, (data) => {
      const order: Order = data.order;
      setOrders([...orders, order]);
    });

    return () => {
      orderUnsubscribe(mint);
    };
  }, [mint, isLoading]);

  // Sorry
  const wallet = useWallet();

  const tradeBlock = useMemo(
    () => (
      <Card className={styles.tradeCard}>
        <div className={styles.buySellWrapper}>
          {/* Sorry */}
          <Button
            className={styles.buyButton}
            onClick={async () => {
              if (!mint) {
                console.warn('no mint');
                return;
              }
              console.log(wallet);
              await buyToken(wallet, {
                mint: new PublicKey(mint),
                solToSpend: 1_000n,
                minTokenToReceive: 0n // todo: what is the proper value?
              });
            }
          }>Buy</Button>
          <Button className={styles.sellButton}>Sell</Button>
        </div>
        <Input placeholder="SOL" align="right" className={styles.tradeInput} />
        <div className={styles.chipsWrapper}>
          <Button className={styles.chipButton}>1%</Button>
          <Button className={styles.chipButton}>2%</Button>
          <Button className={styles.chipButton}>5%</Button>
          <Button className={styles.chipButton}>10%</Button>
        </div>
        <Button className={styles.tradeButton}>Trade</Button>
      </Card>
    ),
    []
  );

  const infoBlock = useMemo(
    () => (
      <>
        <div className={styles.links}>
          <a
            className={styles.socialLink}
            target="_blank"
            rel="noopener noreferrer"
            href="/"
          >
            SolGenAI.com
          </a>
          <a
            className={styles.socialLink}
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com"
          >
            <Twitter width={24} height={24} />
            SolGenAI
          </a>
          <a
            className={styles.socialLink}
            href={'https://telegram.org'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Telegram width={24} height={24} />
            SolGenAI
          </a>
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.infoImage}>
            <Avatar
              src={token?.token.meta.imageUrl || ''}
              size={133}
              noborder
            />
          </div>
          <div className={styles.info}>
            <h3 className={styles.infoName}>{token?.token.meta.name || ''}</h3>
            <p className={styles.infoDescription}>
              {token?.token.meta.description || ''}
            </p>
          </div>
        </div>
        <div className={styles.bondingWrapper}>
          <h4 className={styles.percent}>
            Bonding curve progress:{' '}
            {Math.round((token?.stats.bondingRate || 0) * 100) || 0}%
          </h4>
          <ProgressBar
            progress={Math.round((token?.stats.bondingRate || 0) * 100) || 0}
            withLabel={false}
          />
          <div className={styles.bondingInfo}>
            when the market cap reaches $0 all the liquidity from the bonding
            curve will be deposited into Raydium and burned. progression
            increases as the price goes up. there are 0 tokens still available
            for sale in the bonding curve and there is 0 SOL in the bonding
            curve.
          </div>
        </div>
      </>
    ),
    [token]
  );

  const chartBlock = useMemo(
    () => (
      <div className={styles.chartWrapper}>
        <div ref={chartRef} className={styles.chart} />
      </div>
    ),
    [chartRef]
  );

  const ordersBlock = useMemo(
    () => <TransactionTable data={orders} tokenName={token?.token.meta.name} />,
    [orders, token]
  );

  const commentBlock = useMemo(
    () => (
      <Card className={styles.commentCard}>
        <Button className={styles.commentButton}>
          <span>Comment</span>
          <div>
            <PlusSmall />
          </div>
        </Button>
        <div className={styles.replyWrapper}>
          <div className={styles.replyHeader}>
            <Avatar src={'/doge.png'} size={24} type={'circle'} />
            <span className={styles.replyAuthor}>Kopernick</span>
            <span>24:02</span>
            <span>Reply</span>
            <span>
              <Heart />
              1111
            </span>
          </div>
          <div className={styles.replyContent}>
            When the market cap reached zero all the liquidity from the bonding
            curve will be deposited inti Raydium and burn progression increases
            as the price goes up.When the market cap reached zero all the
            liquidity from the bonding curve will be deposited inti Raydium and
            burn progression increases as the price goes up.
          </div>
        </div>
      </Card>
    ),
    []
  );

  const bottomTabs = [
    {
      label: 'info',
      icon: <Zap />,
    },
    {
      label: 'chart',
      icon: <Chart />,
    },
    {
      label: 'trade',
      icon: <Trade />,
    },
    {
      label: 'txs',
      icon: <Table />,
    },
  ];

  return (
    <div className={styles.container}>
      <Container>
        {width > 1024 ? (
          <div className={styles.desktopGrid}>
            <Row>
              <Col size={8} lg={{ size: 7 }}>
                {chartBlock}
              </Col>
              <Col size={4} lg={{ size: 5 }}>
                {tradeBlock}
                {infoBlock}
              </Col>
            </Row>
            <Row>
              <Col size={8} lg={{ size: 7 }}>
                {ordersBlock}
              </Col>
              <Col size={4} lg={{ size: 5 }}>
                {commentBlock}
              </Col>
            </Row>
          </div>
        ) : (
          <div className={styles.mobileTabContent}>
            {selectedTab === 0 && infoBlock}
            {selectedTab === 1 && chartBlock}
            {selectedTab === 2 && (
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 23 }}
              >
                {tradeBlock}
                {commentBlock}
              </div>
            )}
            {selectedTab === 3 && ordersBlock}
          </div>
        )}
      </Container>
      {width && width <= 1024 && (
        <BottomTabs
          items={bottomTabs}
          selected={selectedTab}
          onChange={setSelectedTab}
        />
      )}
    </div>
  );
};
