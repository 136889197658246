import { FC, SVGProps } from 'react';

const Copy: FC<SVGProps<SVGSVGElement>> = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="4" width="13" height="13" rx="2" fill="currentColor" />
      <rect
        x="0.5"
        y="4.5"
        width="12"
        height="12"
        rx="1.5"
        stroke="currentColor"
      />
    </svg>
  );
};

export default Copy;
