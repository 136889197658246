import { FC } from 'react';
import styles from './styles.module.css';

interface AvatarProps {
  src: string;
  size?: number;
  type?: 'circle' | 'rounded';
  noborder?: boolean;
  color?: string;
  onClick?: () => void;
  hovered?: boolean;
  className?: string;
}

export const Avatar: FC<AvatarProps> = (props: AvatarProps) => {
  const {
    src,
    size = 50,
    type = 'rounded',
    noborder = false,
    hovered = false,
    color = '#00FFE0',
    className = '',
    onClick,
  } = props;
  return (
    <div
      className={`${styles.avatar} ${className} ${styles[type]} ${
        noborder ? styles.noborder : ''
      } ${hovered ? styles.hovered : ''}`}
      style={{
        backgroundImage: `url(${src})`,
        borderColor: color,
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        maxWidth: size,
        maxHeight: size,
      }}
      onClick={onClick}
    />
  );
};
