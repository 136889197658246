import { FC, SVGProps } from 'react';

const Discord: FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.9308 1.32631C15.6561 0.71242 14.2892 0.260131 12.8599 0.00108666C12.8339 -0.00391282 12.8079 0.00858115 12.7945 0.0335697C12.6187 0.361747 12.4239 0.789881 12.2876 1.12639C10.7503 0.884845 9.22099 0.884845 7.71527 1.12639C7.57887 0.782401 7.37707 0.361747 7.20048 0.0335697C7.18707 0.00941489 7.16107 -0.00307908 7.13504 0.00108666C5.70659 0.259303 4.33963 0.711592 3.06411 1.32631C3.05307 1.33131 3.04361 1.33965 3.03732 1.35047C0.444493 5.4161 -0.265792 9.3818 0.0826501 13.2983C0.0842267 13.3175 0.0944749 13.3358 0.108665 13.3475C1.81934 14.666 3.47642 15.4665 5.10273 15.9971C5.12876 16.0054 5.15634 15.9954 5.1729 15.9729C5.55761 15.4215 5.90054 14.8401 6.19456 14.2287C6.21192 14.1929 6.19535 14.1504 6.15989 14.1363C5.61594 13.9197 5.098 13.6557 4.59977 13.3558C4.56037 13.3317 4.55721 13.2725 4.59347 13.2442C4.69831 13.1617 4.80318 13.0759 4.9033 12.9893C4.92141 12.9735 4.94665 12.9701 4.96794 12.9801C8.24107 14.5486 11.7846 14.5486 15.0191 12.9801C15.0404 12.9693 15.0657 12.9726 15.0846 12.9885C15.1847 13.0751 15.2895 13.1617 15.3952 13.2442C15.4314 13.2725 15.4291 13.3317 15.3897 13.3558C14.8914 13.6615 14.3735 13.9197 13.8288 14.1354C13.7933 14.1496 13.7775 14.1929 13.7949 14.2287C14.0952 14.8393 14.4381 15.4207 14.8157 15.9721C14.8315 15.9954 14.8599 16.0054 14.8859 15.9971C16.5201 15.4665 18.1772 14.666 19.8879 13.3475C19.9028 13.3358 19.9123 13.3183 19.9139 13.2992C20.3309 8.77122 19.2154 4.83804 16.9568 1.3513C16.9513 1.33965 16.9419 1.33131 16.9308 1.32631ZM6.68335 10.9136C5.69792 10.9136 4.88594 9.96403 4.88594 8.79789C4.88594 7.63175 5.68217 6.6822 6.68335 6.6822C7.69239 6.6822 8.49651 7.64009 8.48073 8.79789C8.48073 9.96403 7.68451 10.9136 6.68335 10.9136ZM13.329 10.9136C12.3435 10.9136 11.5316 9.96403 11.5316 8.79789C11.5316 7.63175 12.3278 6.6822 13.329 6.6822C14.338 6.6822 15.1421 7.64009 15.1264 8.79789C15.1264 9.96403 14.338 10.9136 13.329 10.9136Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Discord;
