import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Root } from './routes/Root';
import { Detail } from './routes/Detail';
import { Create } from './routes/Create';
import { User } from './routes/User';
import { Layout } from './components/Layout';
import { Wallet } from './providers/wallet';
import DataProvider from './providers/data';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Layout>
        <Root />
      </Layout>
    ),
  },
  {
    path: '/:mint',
    element: (
      <Layout>
        <Detail />
      </Layout>
    ),
  },
  {
    path: '/create',
    element: (
      <Layout>
        <Create />
      </Layout>
    ),
  },
  {
    path: '/user/:address',
    element: (
      <Layout>
        <User />
      </Layout>
    ),
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Wallet>
      <DataProvider>
        <RouterProvider router={router} />
      </DataProvider>
      <Toaster
        position="bottom-right"
        toastOptions={{ style: { backgroundColor: 'black', color: 'white' } }}
      />
    </Wallet>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
