import { FC, SVGProps } from 'react';

const Heart: FC<SVGProps<SVGSVGElement>> = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.8434 1.64929C13.4768 1.28493 13.0417 0.995898 12.5627 0.798699C12.0837 0.601499 11.5704 0.5 11.0519 0.5C10.5335 0.5 10.0201 0.601499 9.54111 0.798699C9.06215 0.995898 8.62698 1.28493 8.26046 1.64929L7.49981 2.40512L6.73916 1.64929C5.99882 0.913658 4.9947 0.500381 3.94771 0.500381C2.90071 0.500381 1.89659 0.913658 1.15626 1.64929C0.415918 2.38493 7.80073e-09 3.38267 0 4.42302C-7.80073e-09 5.46336 0.415918 6.4611 1.15626 7.19674L1.91691 7.95256L7.49981 13.5L13.0827 7.95256L13.8434 7.19674C14.21 6.83255 14.5009 6.40014 14.6994 5.92422C14.8979 5.44829 15 4.93818 15 4.42302C15 3.90785 14.8979 3.39774 14.6994 2.92182C14.5009 2.44589 14.21 2.01348 13.8434 1.64929Z"
        fill="#FC6868"
      />
    </svg>
  );
};

export default Heart;
