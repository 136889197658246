import { FC, ReactNode } from 'react';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';

interface CardProps
  extends React.HTMLAttributes<HTMLDivElement | HTMLAnchorElement> {
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
  link?: string;
}

export const Card: FC<CardProps> = (props: CardProps) => {
  const { children, className = '', onClick, link, ...rest } = props;
  return link ? (
    <Link className={`${styles.container} ${className}`} to={link} {...rest}>
      {children}
    </Link>
  ) : (
    <div
      className={`${styles.container} ${
        onClick ? styles.hover : ''
      } ${className}`}
      onClick={onClick}
      {...rest}
    >
      {children}
    </div>
  );
};
