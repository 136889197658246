import { FC, SVGProps } from 'react';

const PersonsBig: FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="27"
      height="22"
      viewBox="0 0 27 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.1818 21V18.7778C19.1818 17.599 18.7029 16.4686 17.8505 15.6351C16.998 14.8016 15.8419 14.3333 14.6364 14.3333H5.54545C4.33992 14.3333 3.18377 14.8016 2.33133 15.6351C1.47889 16.4686 1 17.599 1 18.7778V21M26 21V18.7778C25.9992 17.793 25.664 16.8364 25.047 16.0581C24.43 15.2798 23.5661 14.724 22.5909 14.4778M18.0455 1.14444C19.0232 1.38922 19.8898 1.94522 20.5087 2.72479C21.1275 3.50436 21.4635 4.46314 21.4635 5.45C21.4635 6.43686 21.1275 7.39564 20.5087 8.17521C19.8898 8.95478 19.0232 9.51078 18.0455 9.75556M14.6364 5.44444C14.6364 7.89904 12.6013 9.88889 10.0909 9.88889C7.58052 9.88889 5.54545 7.89904 5.54545 5.44444C5.54545 2.98985 7.58052 1 10.0909 1C12.6013 1 14.6364 2.98985 14.6364 5.44444Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PersonsBig;
