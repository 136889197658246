import { ReactNode, FC, useMemo } from 'react';
import styles from './styles.module.css';

type Breakpoint = {
  size: number;
};

interface ColProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  size?: number;
  className?: string;
  xs?: Breakpoint;
  sm?: Breakpoint;
  md?: Breakpoint;
  lg?: Breakpoint;
  xl?: Breakpoint;
}

export const Col: FC<ColProps> = (props: ColProps) => {
  const { children, size, className = '', xs, sm, md, lg, xl, ...rest } = props;
  const colWidth = useMemo(() => {
    return [
      size && styles[`col-${size}`],
      xs && styles[`col-xs-${xs.size}`],
      sm && styles[`col-sm-${sm.size}`],
      md && styles[`col-md-${md.size}`],
      lg && styles[`col-lg-${lg.size}`],
      xl && styles[`col-xl-${xl.size}`],
    ]
      .filter(Boolean)
      .join(' ');
  }, [size, xs, sm, md, lg, xl]);

  return (
    <div className={`${styles.col} ${colWidth} ${className}`} {...rest}>
      {children}
    </div>
  );
};
