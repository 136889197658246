import { FC } from 'react';
import { Table } from '../../components/Table';
import { createColumnHelper } from '@tanstack/react-table';
import styles from './styles.module.css';
import { Order } from '../../interfaces/token';

interface TokenTableProps {
  data?: Order[];
  tokenName?: string;
}

export const TransactionTable: FC<TokenTableProps> = (
  props: TokenTableProps
) => {
  const { data = [], tokenName = 'Token' } = props;

  const columnHelper = createColumnHelper<Order>();
  const tableColumns = [
    columnHelper.accessor('maker', {
      header: 'Account',
      size: 200,
      cell: (value) => {
        return (
          <div
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            className={styles.cell}
          >
            {value.getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor('tokenAmountIn', {
      header: 'Type',
      cell: (value) => {
        return (
          <div
            className={`${styles[value.getValue() === null ? 'sell' : 'buy']} ${
              styles.cell
            }`}
          >
            {value.getValue() === null ? 'Sell' : 'Buy'}
          </div>
        );
      },
    }),
    columnHelper.accessor('tokenAmountOut', {
      header: 'Sol',
      sortingFn: (a, b) =>
        Number(a.original.quoteAmountIn || a.original.quoteAmountOut) <
        Number(b.original.quoteAmountIn || b.original.quoteAmountOut)
          ? -1
          : 1,
      cell: (value) => {
        return (
          <div
            className={`${
              styles[value.row.original.tokenAmountIn === null ? 'sell' : 'buy']
            } ${styles.cell}`}
          >
            {value.row.original.quoteAmountIn ||
              value.row.original.quoteAmountOut}
          </div>
        );
      },
    }),
    columnHelper.accessor('quoteAmountIn', {
      header: tokenName,
      sortingFn: (a, b) =>
        Number(a.original.tokenAmountIn || a.original.tokenAmountOut) <
        Number(b.original.tokenAmountIn || b.original.tokenAmountOut)
          ? -1
          : 1,
      cell: (value) => {
        return (
          <div
            className={`${
              styles[value.row.original.tokenAmountIn === null ? 'sell' : 'buy']
            } ${styles.cell}`}
          >
            {value.row.original.tokenAmountIn ||
              value.row.original.tokenAmountOut}
          </div>
        );
      },
    }),
    columnHelper.accessor('time', {
      header: 'Date',
      size: 213,
      sortingFn: (a, b) =>
        new Date(a.original.time).getTime() <
        new Date(b.original.time).getTime()
          ? -1
          : 1,
      cell: (value) => {
        return (
          <div className={styles.cell}>
            {new Date(value.getValue()).toLocaleString()}
          </div>
        );
      },
    }),
    columnHelper.accessor('transaction_signature', {
      header: 'Transaction',
      size: 200,
      cell: (value) => {
        return (
          <div
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            className={styles.cell}
          >
            {value.getValue()}
          </div>
        );
      },
    }),
  ];

  return (
    <Table
      data={data}
      columns={tableColumns}
      justify="center"
      rowClassName={styles.row}
    />
  );
};
