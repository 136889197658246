import { FC, SVGProps } from 'react';

const CloudBig: FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.8134 25.5724C30.6905 25.1911 32.3589 24.1255 33.4949 22.5826C34.6309 21.0397 35.1534 19.1295 34.9609 17.2229C34.7684 15.3162 33.8747 13.5492 32.4533 12.2647C31.0318 10.9802 29.1841 10.27 27.2687 10.272H25.3224C24.8107 8.28958 23.8149 6.46538 22.4244 4.96315C21.034 3.46091 19.2925 2.32767 17.3562 1.66516C15.4199 1.00266 13.3495 0.831625 11.3308 1.16743C9.31214 1.50323 7.40842 2.33534 5.79056 3.58907C4.1727 4.8428 2.89135 6.4789 2.06156 8.35046C1.23176 10.222 0.879488 12.2705 1.03637 14.3119C1.19326 16.3533 1.85438 18.3238 2.96039 20.0464C4.06641 21.7691 5.58268 23.19 7.37309 24.1815M19.5453 16.454L13.3665 25.727H22.6347L16.4559 35"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.8134 25.5724C30.6905 25.1911 32.3589 24.1255 33.4949 22.5826C34.6309 21.0397 35.1534 19.1295 34.9609 17.2229C34.7684 15.3162 33.8747 13.5492 32.4533 12.2647C31.0318 10.9802 29.1841 10.27 27.2687 10.272H25.3224C24.8107 8.28958 23.8149 6.46538 22.4244 4.96315C21.034 3.46091 19.2925 2.32767 17.3562 1.66516C15.4199 1.00266 13.3495 0.831625 11.3308 1.16743C9.31214 1.50323 7.40842 2.33534 5.79056 3.58907C4.1727 4.8428 2.89135 6.4789 2.06156 8.35046C1.23176 10.222 0.879488 12.2705 1.03637 14.3119C1.19326 16.3533 1.85438 18.3238 2.96039 20.0464C4.06641 21.7691 5.58268 23.19 7.37309 24.1815M19.5453 16.454L13.3665 25.727H22.6347L16.4559 35"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloudBig;
