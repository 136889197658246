import { FC, SVGProps } from 'react';

const Twitter: FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_517_1128)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6162 21.0833L9.52922 13.8326L3.16097 21.0833H0.466797L8.33392 12.1285L0.466797 0.916672H7.38391L12.1783 7.75044L18.1855 0.916672H20.8797L13.3776 9.45678L21.5333 21.0833H14.6162ZM17.6165 19.0392H15.8026L4.32445 2.96084H6.13852L10.7356 9.39874L11.5306 10.5159L17.6165 19.0392Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_517_1128">
          <rect width="22" height="22" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Twitter;
