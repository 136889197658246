import { BaseWalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Button } from '../Button';
import { Col } from '../Col';
import { Container } from '../Container';
import { Row } from '../Row';
import Plus from '../svg/Plus';
import Telegram from '../svg/Telegram';
import Twitter from '../svg/Twitter';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { LABELS } from '../../services/web3Service';
import Plus24 from '../svg/Plus24';
import Search22 from '../svg/Search22';
import Burger25 from '../svg/Burger25';

export const Header = () => {
  return (
    <header className={`${styles.container}`}>
      <Container>
        <Row>
          <Col size={4} className={styles.logoLinksWrapper}>
            <Link to="/">
              <img src="/logo.svg" alt="logo" className={styles.logo} />
            </Link>
            <nav className={styles.nav}>
              <a href="/docs" className={styles.link}>
                docs
              </a>
              <a href="/support" className={styles.link}>
                support
              </a>
            </nav>
          </Col>
          <Col size={4} className={styles.createWrapper}>
            <Link to="/create" className={styles.linkReset}>
              <Button className={styles.createTokenButton}>
                <span>create token</span>
                <Plus className={styles.plus35} />
                <Plus24 className={styles.plus24} />
              </Button>
            </Link>
          </Col>
          <Col size={4} className={styles.connectWrapper}>
            <div className={styles.socialLinks}>
              <a
                className={styles.socialLink}
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com"
              >
                <Twitter />
              </a>
              <a
                className={styles.socialLink}
                target="_blank"
                rel="noreferrer"
                href="https://telegram.org"
              >
                <Telegram />
              </a>
            </div>
            <div className={styles.connectWalletButton}>
              <BaseWalletMultiButton
                style={{
                  background: 'transparent',
                  borderRadius: 100,
                  height: '56px',
                  color: 'white',
                  padding: '20px',
                  fontSize: '16px',
                  lineHeight: '18.24px',
                  fontWeight: 500,
                  fontFamily: 'inherit',
                }}
                labels={LABELS}
              />
            </div>
            <div className={styles.menuWrapper}>
              <div className={styles.menuElement}>
                <Search22 />
              </div>
              <div className={styles.menuElement}>
                <Burger25 />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};
