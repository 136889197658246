import { FC, SVGProps } from 'react';

const Table: FC<SVGProps<SVGSVGElement>> = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.428571 0C0.192857 0 0 0.203571 0 0.452381V5.88095H8.57143V0H0.428571ZM9.42857 0V5.88095H18V0.452381C18 0.203571 17.8071 0 17.5714 0H9.42857ZM0 6.78571V12.2143H8.57143V6.78571H0ZM9.42857 6.78571V12.2143H18V6.78571H9.42857ZM0 13.119V18.5476C0 18.7964 0.192857 19 0.428571 19H8.57143V13.119H0ZM9.42857 13.119V19H17.5714C17.8071 19 18 18.7964 18 18.5476V13.119H9.42857Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Table;
