import {Candle, TimePriceble} from "./types";
import {getPrice} from "./get-price";

interface CandleOptions {
	duration: number;
}

export const toCandleStart = (realTime: number, duration: number): number =>
	Math.ceil(realTime / duration) * duration
;

export const createCandles = (orders: Array<TimePriceble>, candleOptions: CandleOptions) => {
	const pricesObj: Record<number, Array<number>> = {};

	const sortedOrders = orders.sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime());

	for (const order of sortedOrders) {
		const realTime = new Date(order.time).getTime() / 1000;
		const time = toCandleStart(realTime, candleOptions.duration);
		const price = getPrice(order);

		if (!price) {
			continue;
		}

		if (!pricesObj[time]) {
			pricesObj[time] = [];
		}

		pricesObj[time].push(price);
	}

	const prices = [];
	for (const time in pricesObj) {
		prices.push({
			time: Number(time),
			open: pricesObj[time][0],
			close: pricesObj[time][pricesObj[time].length - 1],
			low: Math.min(...pricesObj[time]),
			high: Math.max(...pricesObj[time]),
		});
	}

	return prices;
}

// не используется сейчас
// возможно будет полезно в дальнейшем для оптимизации, когда много ордеров
export const addPrice = (candles: Array<Candle>, candleOptions: CandleOptions, realTime: number, price: number) => {
	const time = toCandleStart(realTime, candleOptions.duration);

	const candleIndex = candles.findIndex((candle) => candle.time === time);

	if (!candleIndex) {
		candles.push({
			time,
			open: price,
			close: price,
			low: price,
			high: price,
		});
	} else {
		candles[candleIndex].low = Math.min(candles[candleIndex].low, price);
		candles[candleIndex].high = Math.max(candles[candleIndex].high, price);
		candles[candleIndex].close = price;
	}

	return candles.sort((a, b) => a.time - b.time);
}