import { FC, SVGProps } from 'react';

const Plus: FC<SVGProps<SVGSVGElement>> = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="17.5"
        cy="17.5"
        r="16.5"
        stroke="currentColor"
        strokeWidth="2"
      />
      <path
        d="M17.7028 10.2253V25.1817M10.2246 17.7035H25.181"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Plus;
