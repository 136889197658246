import { FC, SVGProps } from 'react';

const Zap: FC<SVGProps<SVGSVGElement>> = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="12"
      height="21"
      viewBox="0 0 12 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.46149 0.5L0 12.3181H5.53851V20.5L12 8.68194H6.46149V0.5Z"
        fill="CurrentColor"
      />
    </svg>
  );
};

export default Zap;
