import { useCallback, useState } from 'react';
import { Avatar } from '../../components/Avatar';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { Col } from '../../components/Col';
import { Container } from '../../components/Container';
import { Row } from '../../components/Row';
import ArrowUpRight from '../../components/svg/ArrowUpRight';
import Copy from '../../components/svg/Copy';
import Telegram from '../../components/svg/Telegram';
import Twitter from '../../components/svg/Twitter';
import styles from './styles.module.css';
import Lock from '../../components/svg/Lock';
import Stack2 from '../../components/svg/Stack2';
import PersonsBig from '../../components/svg/PersonsBig';
import PersonPlus from '../../components/svg/PersonPlus';

export const User = () => {
  const tabs = [
    { name: 'coin held', icon: <Lock style={{ color: '#00FF94' }} /> },
    { name: 'coin created', icon: <Stack2 style={{ color: '#00FF94' }} /> },
    { name: 'followers', icon: <PersonsBig style={{ color: '#00FF94' }} /> },
    { name: 'following', icon: <PersonPlus style={{ color: '#00FF94' }} /> },
  ];
  const [activeTab, setActiveTab] = useState(0);
  const [copyId, setCopyId] = useState<string | null>(null);
  const copy = useCallback(
    (value: string, id: string) => {
      navigator.clipboard.writeText(value);
      setCopyId(id);
      setTimeout(() => {
        setCopyId(null);
      }, 1000);
    },
    [setCopyId]
  );
  return (
    <div className={styles.container}>
      <Container>
        <Row>
          <Col size={8} className={styles.col}>
            <Card className={styles.card}>
              <div className={styles.userBlock}>
                <div className={styles.userInfo}>
                  <div className={styles.avatarBlock}>
                    <Avatar size={111} src={'/doge.png'} />
                  </div>
                  <div className={styles.userDetails}>
                    <div className={styles.userNameRow}>
                      <div className={styles.userName}>Ductser</div>
                      <div className={styles.userLinks}>
                        <a
                          href="https://twitter.com"
                          className={styles.userLink}
                        >
                          <Twitter />
                        </a>
                        <a
                          href="https://telegram.com"
                          className={styles.userLink}
                        >
                          <Telegram />
                        </a>
                      </div>
                    </div>
                    <div className={styles.userFollowers}>
                      Followers: <span className={styles.green}>100</span>
                    </div>
                    <div
                      className={styles.userAddress}
                      style={
                        copyId === 'copy'
                          ? {
                              color: '#00ff94',
                            }
                          : {}
                      }
                      onClick={() => {
                        copy(
                          '2gBVjqsn3jAg7YkJmVoQDztKD5tXjnGaDaQz1oxe3CYg',
                          'copy'
                        );
                      }}
                    >
                      <Copy style={{ minWidth: 17 }} />
                      <span>
                        &nbsp; 2gBVjqsn3jAg7YkJmVoQDztKD5tXjnGaDaQz1oxe3CYg
                      </span>
                    </div>
                    <a
                      className={styles.userSolscan}
                      href="https://solscan.io"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ArrowUpRight />
                      <span>&nbsp; View on solscan</span>
                    </a>
                  </div>
                </div>
                <div className={styles.userActions}>
                  <Button className={styles.followButton}>Follow</Button>
                </div>
              </div>
              <div className={styles.tabs}>
                {tabs.map((tab, index) => (
                  <div
                    key={tab.name}
                    className={`${styles.tab} ${
                      index === activeTab ? styles.activeTab : ''
                    }`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.icon}
                    {tab.name}
                  </div>
                ))}
              </div>
              {activeTab === 0 && <div>coin held</div>}
              {activeTab === 1 && <div>coin created</div>}
              {activeTab === 2 && <div>followers</div>}
              {activeTab === 3 && <div>following</div>}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
