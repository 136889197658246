import { ReactNode, FC } from 'react';
import styles from './styles.module.css';

interface ContainerProps {
  children: ReactNode;
}

export const Container: FC<ContainerProps> = (props: ContainerProps) => {
  const { children } = props;
  return <div className={styles.container}>{children}</div>;
};
