import { FC, useRef, useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import styles from './styles.module.css';
import ChevronUp from '../svg/ChevronUp';
import ChevronDown from '../svg/ChevronDown';
import ArrowUpFill from '../svg/ArrowUpFill';
import ArrowDownFill from '../svg/ArrowDownFill';

interface TableProps {
  data: any[];
  columns: any[];
  onClick?: (data: any) => void;
  justify?: 'flex-start' | 'center' | 'flex-end';
  headerStyle?: any;
  rowClassName?: any;
  wrapperClassName?: any;
  height?: number;
}

export const Table: FC<TableProps> = (props: TableProps) => {
  const {
    data,
    columns,
    onClick,
    justify = 'flex-start',
    headerStyle = {},
    rowClassName = '',
    wrapperClassName = '',
    height = 600,
  } = props;
  const [sorting, setSorting] = useState<SortingState>([]);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' &&
      navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  return (
    <div
      style={{
        overflow: 'auto',
        position: 'relative',
        height,
      }}
      className={`${styles.wrapper} ${wrapperClassName}`}
      ref={tableContainerRef}
    >
      <table className={styles.table} style={{ display: 'grid' }}>
        <thead
          className={styles.thead}
          style={{
            display: 'grid',
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} style={{ display: 'flex', width: '100%' }}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  style={{
                    ...{
                      display: 'flex',
                      width: header.getSize(),
                      justifyContent:
                        (header.column.columnDef.meta as any)?.justifyContent ||
                        justify,
                    },
                    ...headerStyle,
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      style={{
                        display: 'flex',
                        gap: 7,
                        justifyContent: justify,
                        alignItems: 'center',
                        textDecoration: header.column.getIsSorted()
                          ? 'underline'
                          : 'none',
                      }}
                      className={header.column.getCanSort() ? styles.sort : ''}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: <ArrowUpFill />,
                        desc: <ArrowDownFill />,
                      }[header.column.getIsSorted() as string] ?? (
                        <div className={styles.invisibleBox} />
                      )}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className={styles.tbody}
          style={{
            display: 'grid',
            height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
            position: 'relative', //needed for absolute positioning of rows
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const row = rows[virtualRow.index] as Row<any>;
            return (
              <tr
                data-index={virtualRow.index} //needed for dynamic row height measurement
                ref={(node) => rowVirtualizer.measureElement(node)} //measure dynamic row height
                key={row.id}
                style={{
                  ...{
                    display: 'flex',
                    position: 'absolute',
                    transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                    width: '100%',
                  },
                  ...(!!onClick ? { cursor: 'pointer' } : {}),
                }}
                onClick={() => {
                  if (!!onClick) {
                    onClick(row.original);
                  }
                }}
                className={rowClassName}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      style={{
                        display: 'flex',
                        width: cell.column.getSize(),
                        justifyContent: justify,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {table.getRowModel().rows.length === 0 && (
            <tr>
              <td colSpan={columns.length} align="center">
                No data
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
