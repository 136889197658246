import { FC, SVGProps } from 'react';

const PersonPlus: FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 22.375V20.2917C18 19.1866 17.5259 18.1268 16.682 17.3454C15.8381 16.564 14.6935 16.125 13.5 16.125H5.625C4.43153 16.125 3.28693 16.564 2.44302 17.3454C1.59911 18.1268 1.125 19.1866 1.125 20.2917V22.375M22.5 8.83333V15.0833M25.875 11.9583H19.125M14.0625 7.79167C14.0625 10.0929 12.0478 11.9583 9.5625 11.9583C7.07722 11.9583 5.0625 10.0929 5.0625 7.79167C5.0625 5.49048 7.07722 3.625 9.5625 3.625C12.0478 3.625 14.0625 5.49048 14.0625 7.79167Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PersonPlus;
