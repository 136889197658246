import { FC, ReactNode } from 'react';
import styles from './styles.module.css';

interface InputProps {
  placeholder?: string;
  prefix?: ReactNode;
  value?: string;
  style?: StyleSheet;
  className?: string;
  align?: 'left' | 'center' | 'right';
  onChange?: (val: string) => void;
}

export const Input: FC<InputProps> = (props: InputProps) => {
  const {
    placeholder,
    prefix,
    value,
    onChange = () => {},
    className = '',
    align = 'left',
    style = {},
  } = props;
  return (
    <div className={`${styles.inputWrapper} ${className}`} style={style}>
      <div>{prefix}</div>
      <div className={styles.input}>
        <input
          className={styles['align-' + align]}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      </div>
    </div>
  );
};
