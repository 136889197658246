import { ReactNode, FC } from 'react';
import styles from './styles.module.css';

interface ListProps {
  data: any[];
  renderItem: (item: any) => ReactNode;
  ordered?: boolean;
  gap?: number;
  itemClassName?: string;
}

export const List: FC<ListProps> = (props: ListProps) => {
  const { data, renderItem, ordered, gap = 10, itemClassName = '' } = props;
  return (
    <ul className={styles.list} style={{ gap }}>
      {data.map((item, index) => (
        <li key={index} className={`${styles.item} ${itemClassName}`}>
          {ordered && <span className={styles.index}>{index + 1}</span>}
          <div className={styles[ordered ? 'ordered' : 'unordered']}>
            {renderItem(item)}
          </div>
        </li>
      ))}
    </ul>
  );
};
