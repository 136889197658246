import { FC, SVGProps } from 'react';

const Persons: FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.8182 16V14.3333C14.8182 13.4493 14.4542 12.6014 13.8064 11.9763C13.1585 11.3512 12.2798 11 11.3636 11H4.45455C3.53834 11 2.65967 11.3512 2.01181 11.9763C1.36396 12.6014 1 13.4493 1 14.3333V16M20 16V14.3333C19.9994 13.5948 19.7447 12.8773 19.2757 12.2936C18.8068 11.7099 18.1502 11.293 17.4091 11.1083M13.9545 1.10833C14.6976 1.29192 15.3563 1.70892 15.8266 2.29359C16.2969 2.87827 16.5522 3.59736 16.5522 4.3375C16.5522 5.07764 16.2969 5.79673 15.8266 6.38141C15.3563 6.96608 14.6976 7.38308 13.9545 7.56667M11.3636 4.33333C11.3636 6.17428 9.81698 7.66667 7.90909 7.66667C6.0012 7.66667 4.45455 6.17428 4.45455 4.33333C4.45455 2.49238 6.0012 1 7.90909 1C9.81698 1 11.3636 2.49238 11.3636 4.33333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8182 16V14.3333C14.8182 13.4493 14.4542 12.6014 13.8064 11.9763C13.1585 11.3512 12.2798 11 11.3636 11H4.45455C3.53834 11 2.65967 11.3512 2.01181 11.9763C1.36396 12.6014 1 13.4493 1 14.3333V16M20 16V14.3333C19.9994 13.5948 19.7447 12.8773 19.2757 12.2936C18.8068 11.7099 18.1502 11.293 17.4091 11.1083M13.9545 1.10833C14.6976 1.29192 15.3563 1.70892 15.8266 2.29359C16.2969 2.87827 16.5522 3.59736 16.5522 4.3375C16.5522 5.07764 16.2969 5.79673 15.8266 6.38141C15.3563 6.96608 14.6976 7.38308 13.9545 7.56667M11.3636 4.33333C11.3636 6.17428 9.81698 7.66667 7.90909 7.66667C6.0012 7.66667 4.45455 6.17428 4.45455 4.33333C4.45455 2.49238 6.0012 1 7.90909 1C9.81698 1 11.3636 2.49238 11.3636 4.33333Z"
        stroke="url(#paint0_linear_1518_333)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1518_333"
          x1="10.5"
          y1="1"
          x2="10.5"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Persons;
