import { FC, SVGProps } from 'react';

const Cloud: FC<SVGProps<SVGSVGElement>> = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.7248 14.5089C16.7185 14.307 17.6018 13.7429 18.2032 12.9261C18.8046 12.1093 19.0812 11.098 18.9793 10.0886C18.8774 9.07918 18.4042 8.14367 17.6517 7.46366C16.8992 6.78366 15.921 6.40767 14.907 6.40869H13.8766C13.6056 5.35919 13.0785 4.39344 12.3423 3.59814C11.6062 2.80284 10.6842 2.20288 9.65915 1.85214C8.63406 1.50141 7.53795 1.41086 6.46925 1.58864C5.40054 1.76641 4.39269 2.20695 3.53618 2.87069C2.67967 3.53443 2.0013 4.40059 1.562 5.39142C1.1227 6.38225 0.936199 7.46672 1.01926 8.54747C1.10231 9.62823 1.45232 10.6714 2.03786 11.5834C2.62339 12.4954 3.42613 13.2476 4.37399 13.7726M10.8181 9.68152L7.54697 14.5908H12.4536L9.18253 19.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.7248 14.5089C16.7185 14.307 17.6018 13.7429 18.2032 12.9261C18.8046 12.1093 19.0812 11.098 18.9793 10.0886C18.8774 9.07918 18.4042 8.14367 17.6517 7.46366C16.8992 6.78366 15.921 6.40767 14.907 6.40869H13.8766C13.6056 5.35919 13.0785 4.39344 12.3423 3.59814C11.6062 2.80284 10.6842 2.20288 9.65915 1.85214C8.63406 1.50141 7.53795 1.41086 6.46925 1.58864C5.40054 1.76641 4.39269 2.20695 3.53618 2.87069C2.67967 3.53443 2.0013 4.40059 1.562 5.39142C1.1227 6.38225 0.936199 7.46672 1.01926 8.54747C1.10231 9.62823 1.45232 10.6714 2.03786 11.5834C2.62339 12.4954 3.42613 13.2476 4.37399 13.7726M10.8181 9.68152L7.54697 14.5908H12.4536L9.18253 19.5"
        stroke="url(#paint0_linear_1518_336)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1518_336"
          x1="2.575"
          y1="21.3871"
          x2="21.4895"
          y2="18.2495"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.975" stopColor="currentColor" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Cloud;
