import { FC } from 'react';
import styles from './styles.module.css';

interface ProgressBarProps {
  progress?: number;
  withLabel?: boolean;
  className?: string;
  labelClassName?: string;
  style?: StyleSheet;
}

export const ProgressBar: FC<ProgressBarProps> = (props: ProgressBarProps) => {
  const {
    progress = 0,
    withLabel = true,
    className = '',
    style = {},
    labelClassName = '',
  } = props;
  return (
    <div className={`${styles.container} ${className}`} style={style}>
      {withLabel && (
        <div className={`${styles.label} ${labelClassName}`}>{progress}%</div>
      )}
      <div className={styles.progressBar}>
        <div className={styles.bar} style={{ width: `${progress}%` }} />
      </div>
    </div>
  );
};
