import { FC, SVGProps } from 'react';

const Chart: FC<SVGProps<SVGSVGElement>> = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.2105 0V19H22V0H16.2105ZM8.10526 4.75V19H13.8947V4.75H8.10526ZM0 9.5V19H5.78947V9.5H0Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Chart;
