import { FC, SVGProps } from 'react';

const Persons13: FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 9V8.11111C9 7.63962 8.78929 7.18743 8.41421 6.85403C8.03914 6.52063 7.53043 6.33333 7 6.33333H3C2.46957 6.33333 1.96086 6.52063 1.58579 6.85403C1.21071 7.18743 1 7.63962 1 8.11111V9M12 9V8.11111C11.9997 7.71721 11.8522 7.33457 11.5807 7.02325C11.3092 6.71194 10.9291 6.48959 10.5 6.39111M8.5 1.05778C8.93021 1.15569 9.31152 1.37809 9.58382 1.68992C9.85612 2.00174 10.0039 2.38526 10.0039 2.78C10.0039 3.17474 9.85612 3.55826 9.58382 3.87008C9.31152 4.18191 8.93021 4.40431 8.5 4.50222M7 2.77778C7 3.75962 6.10457 4.55556 5 4.55556C3.89543 4.55556 3 3.75962 3 2.77778C3 1.79594 3.89543 1 5 1C6.10457 1 7 1.79594 7 2.77778Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9V8.11111C9 7.63962 8.78929 7.18743 8.41421 6.85403C8.03914 6.52063 7.53043 6.33333 7 6.33333H3C2.46957 6.33333 1.96086 6.52063 1.58579 6.85403C1.21071 7.18743 1 7.63962 1 8.11111V9M12 9V8.11111C11.9997 7.71721 11.8522 7.33457 11.5807 7.02325C11.3092 6.71194 10.9291 6.48959 10.5 6.39111M8.5 1.05778C8.93021 1.15569 9.31152 1.37809 9.58382 1.68992C9.85612 2.00174 10.0039 2.38526 10.0039 2.78C10.0039 3.17474 9.85612 3.55826 9.58382 3.87008C9.31152 4.18191 8.93021 4.40431 8.5 4.50222M7 2.77778C7 3.75962 6.10457 4.55556 5 4.55556C3.89543 4.55556 3 3.75962 3 2.77778C3 1.79594 3.89543 1 5 1C6.10457 1 7 1.79594 7 2.77778Z"
        stroke="url(#paint0_linear_1833_612)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1833_612"
          x1="6.5"
          y1="1"
          x2="6.5"
          y2="9"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Persons13;
