import { ReactNode, FC } from 'react';
import styles from './styles.module.css';

interface RowProps {
  children: ReactNode;
  className?: string;
  style?: string;
}

export const Row: FC<RowProps> = (props: RowProps) => {
  const { children, className = '', style = {} } = props;
  return (
    <div className={`${styles.row} ${className}`} style={style}>
      {children}
    </div>
  );
};
