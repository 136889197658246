import { FC, SVGProps } from 'react';

const Stack2: FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.5283 3.66669C14.5296 2.19335 11.499 1 7.7648 1C4.03063 1 1.00271 2.19468 1 3.66669M1 3.66669C1 5.14004 4.02793 6.33339 7.7648 6.33339C11.5017 6.33339 14.5296 5.14004 14.5296 3.66669L14.5296 14.0134M1 3.66669V19.6668C1.00135 21.1401 4.02928 22.3335 7.76481 22.3335C9.57642 22.3335 11.2122 22.0495 12.4258 21.5921M1.00135 9.00006C1.00135 10.4734 4.02928 11.6668 7.76616 11.6668C11.503 11.6668 14.531 10.4734 14.531 9.00006M12.5069 16.2335C11.2866 16.7069 9.61295 17.0002 7.76481 17.0002C4.02928 17.0002 1.00135 15.8069 1.00135 14.3335M22.2171 14.7576C24.5943 17.1003 24.5943 20.9003 22.2171 23.243C19.84 25.5857 15.984 25.5857 13.6069 23.243C11.2297 20.9003 11.2297 17.1003 13.6069 14.7576C15.984 12.4149 19.84 12.4149 22.2171 14.7576Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Stack2;
