import {
  createContext,
  useEffect,
  useState,
  FC,
  ReactNode,
  useContext, useCallback,
} from 'react';
import { TokenItemV2 } from '../interfaces/token';
import {
  fetchBoundingRateTokens,
  fetchKOTHTokens,
  fetchLatestTokens,
  fetchMarketCapTokens,
  fetchRecentlyAddedTokens,
  fetchTransactionCountTokens,
} from '../services/tokenService';
import Pusher from 'pusher-js';

const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY || 'c7373849f192b69acbb4';

interface DataContextProps {
  topBoundingRateTokens: TokenItemV2[];
  topTransactionCountTokens: TokenItemV2[];
  topKOTHTokens: TokenItemV2[];
  topLatestTokens: TokenItemV2[];
  topRecentTokens: TokenItemV2[];
  topMarketCapTokens: TokenItemV2[];
  setAutoUpdate: (value: boolean) => void;
  autoUpdate: boolean;
  isLoading: boolean;
  orderSubscribe: (token: string, cb: (data: any) => void) => void;
  orderUnsubscribe: (token: string) => void;
}

interface DataProviderProps {
  children: ReactNode;
}

export const DataContext = createContext<DataContextProps>({
  topBoundingRateTokens: [],
  topTransactionCountTokens: [],
  topKOTHTokens: [],
  topLatestTokens: [],
  topRecentTokens: [],
  topMarketCapTokens: [],
  setAutoUpdate: () => {},
  autoUpdate: true,
  isLoading: true,
  orderSubscribe: () => {},
  orderUnsubscribe: () => {},
});

const DataProvider: FC<DataProviderProps> = ({
  children,
}: DataProviderProps) => {
  const [topBoundingRateTokens, setTopBoundingRateTokens] = useState<
    TokenItemV2[]
  >([]);
  const [topTransactionCountTokens, setTopTransactionCountTokens] = useState<
    TokenItemV2[]
  >([]);
  const [topKOTHTokens, setTopKOTHTokens] = useState<TokenItemV2[]>([]);
  const [topLatestTokens, setTopLatestTokens] = useState<TokenItemV2[]>([]);
  const [topRecentTokens, setTopRecentTokens] = useState<TokenItemV2[]>([]);
  const [topMarketCapTokens, setTopMarketCapTokens] = useState<TokenItemV2[]>(
    []
  );
  const [pusher, setPusher] = useState<Pusher | null>(null);
  const [channels, setChannels] = useState<Record<string, any>>({});

  const [autoUpdate, setAutoUpdate] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      const [
        dataTopBoundingRateTokens,
        dataTopTransactionCountTokens,
        dataTopKOTHTokens,
        dataTopLatestTokens,
        dataTopRecentTokens,
        dataTopMarketCapTokens,
      ] = await Promise.all([
        fetchBoundingRateTokens(),
        fetchTransactionCountTokens(),
        fetchKOTHTokens(),
        fetchLatestTokens(),
        fetchRecentlyAddedTokens(),
        fetchMarketCapTokens(),
      ]);
      setTopBoundingRateTokens(dataTopBoundingRateTokens);
      setTopTransactionCountTokens(dataTopTransactionCountTokens);
      setTopKOTHTokens(dataTopKOTHTokens);
      setTopLatestTokens(dataTopLatestTokens);
      setTopRecentTokens(dataTopRecentTokens);
      setTopMarketCapTokens(dataTopMarketCapTokens);
    };
    fetchData();

    Pusher.logToConsole = true;
    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: 'eu',
    });
    pusher.connection.bind('error', function (err: any) {
      if (err.data.code === 4004) {
        console.log('Over limit!');
      }
    });
    const channel = pusher.subscribe('token');

    channel.bind('token:created', function (data: any) {
      console.log('token/token:created', JSON.stringify(data));
    });

    channel.bind('token:top-table-changed', function (data: any) {
      console.log('token/token:top-table-changed', JSON.stringify(data));
    });
    setPusher(pusher);

    return () => {
      pusher.disconnect();
    };
  }, []);


  const orderSubscribe = useCallback((token: string, cb: (data: any) => void) => {
    if (!pusher) {
      return;
    }

    const channel = pusher.subscribe(`order-${token}`);
    channel.bind('order:created', cb);

    setChannels({ ...channels, [token]: channel });
  }, [ pusher ]);

  const orderUnsubscribe = useCallback((token: string) => {
    if (!pusher || !channels) {
      return;
    }

    const { token: channel, ...rest } = channels;
    if (!channel) {
      return;
    }

    channel.unsubscribe();

    setChannels(rest);
  }, [pusher]);

  return (
    <DataContext.Provider
      value={{
        topBoundingRateTokens,
        topKOTHTokens,
        topLatestTokens,
        topRecentTokens,
        topTransactionCountTokens,
        topMarketCapTokens,
        setAutoUpdate,
        autoUpdate,
        isLoading: pusher === null,
        orderSubscribe,
        orderUnsubscribe,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;

export const useDataContext = () => {
  return useContext(DataContext);
};
