import { ReactNode, FC } from 'react';
import styles from './styles.module.css';

type TabItem = {
  label: string;
  icon: ReactNode;
};

interface BottomTabsProps {
  items: TabItem[];
  selected: number;
  onChange?: (index: number) => void;
  className?: string;
  style?: string;
}

export const BottomTabs: FC<BottomTabsProps> = (props: BottomTabsProps) => {
  const { items, className = '', style = {}, selected, onChange } = props;
  return (
    <div className={`${styles.tabsWrapper} ${className}`} style={style}>
      {items.map((item, index) => (
        <div
          key={index}
          className={`${styles.tabItem} ${
            index === selected ? styles.selected : ''
          }`}
          onClick={() => onChange && onChange(index)}
        >
          {item.icon}
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
};
