import { FC, SVGProps } from 'react';

const Trade: FC<SVGProps<SVGSVGElement>> = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="23"
      height="19"
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.4615 0C18.4805 0 17.6314 0.528343 17.1723 1.30389C18.7389 1.97839 19.9107 3.37126 20.2459 5.05362C21.3252 4.72458 22.1154 3.75164 22.1154 2.59091C22.1154 1.16245 20.9247 0 19.4615 0ZM15.0385 2.59091C14.1 2.59091 13.2 2.95487 12.5364 3.60272C11.8728 4.25058 11.5 5.12925 11.5 6.04545C11.5 6.96166 11.8728 7.84033 12.5364 8.48819C13.2 9.13604 14.1 9.5 15.0385 9.5C15.9769 9.5 16.8769 9.13604 17.5405 8.48819C18.2041 7.84033 18.5769 6.96166 18.5769 6.04545C18.5769 5.12925 18.2041 4.25058 17.5405 3.60272C16.8769 2.95487 15.9769 2.59091 15.0385 2.59091ZM5.30769 8.63636C4.96905 8.63664 4.63358 8.7002 4.31941 8.8236L0 10.3636V17.2727L3.7337 15.6888C4.17424 15.5023 4.67268 15.4957 5.11764 15.6737L13.1362 18.8718H13.1396C13.3181 18.9557 13.5135 18.9995 13.7115 19C13.9407 18.9992 14.1657 18.9405 14.3646 18.8296H14.3698L22.3193 14.5182L22.3175 14.5165C22.524 14.4045 22.6962 14.2407 22.8161 14.042C22.9361 13.8433 22.9996 13.617 23 13.3864C23 13.0428 22.8602 12.7133 22.6114 12.4703C22.3625 12.2274 22.025 12.0909 21.6731 12.0909C21.4782 12.0916 21.2859 12.1343 21.1098 12.2157V12.214L15.0385 14.6818H13.7115C14.0635 14.6818 14.401 14.5453 14.6498 14.3024C14.8987 14.0594 15.0385 13.7299 15.0385 13.3864C15.0384 13.1131 14.9498 12.8468 14.7853 12.6256C14.6208 12.4044 14.3889 12.2397 14.1227 12.155V12.1533L6.43247 8.87926H6.4221C6.07308 8.72031 5.69281 8.63743 5.30769 8.63636Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Trade;
