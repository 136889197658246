import {
	ComputeBudgetProgram,
	Connection, Keypair,
	PublicKey,
	TransactionInstruction,
	TransactionMessage,
	clusterApiUrl,
	VersionedTransaction,
} from "@solana/web3.js";
import {
	ASSOCIATED_TOKEN_PROGRAM_ID,
	getAssociatedTokenAddressSync,
	TOKEN_PROGRAM_ID,
	createAssociatedTokenAccountIdempotentInstruction,
} from '@solana/spl-token';
import {WalletContextState} from "@solana/wallet-adapter-react";

import { ed25519 } from '@noble/curves/ed25519';

export const LABELS = {
	'change-wallet': 'Change wallet',
	connecting: 'Connecting ...',
	'copy-address': 'Copy address',
	copied: 'Copied',
	disconnect: 'Disconnect',
	'has-wallet': 'Connect',
	'no-wallet': 'connect wallet',
} as const;

const isDev = true;
const connectionURL = isDev ? 'https://chess-beta.web3engineering.co.uk/rpc' : clusterApiUrl('mainnet-beta');
const connection = new Connection(connectionURL, "confirmed");
const PROGRAM = new PublicKey("4funSZoXriGYKYwTAWTA6ip3FNDiEF4ir7Xxx5acNGr4")
const SYSTEM_PROGRAM = new PublicKey("11111111111111111111111111111111");
const FEE_ACCOUNT = new PublicKey("rQ7a19af4EP8uG8SAHUZMyFEMX9qAjq3R5cKEfdzqbq");

export interface CreateTokenData {
	name: string;
	symbol: string;
	uri: string;
}

export interface BuyTokenData {
	mint: PublicKey;
	solToSpend: bigint;
	minTokenToReceive: bigint;
}

type Hex = Uint8Array | string;

const sign = (
	message: Hex,
	secretKey: Hex
) => ed25519.sign(message, secretKey.slice(0, 32));

export const createToken = async (wallet: WalletContextState, tokenData: CreateTokenData) => {
	if (!wallet.publicKey || !wallet.signTransaction) {
		throw new Error('Wallet PublicKey missed');
	}

	const mintKey = Keypair.generate();
	const [ownerPubkey, curveBump] = PublicKey.findProgramAddressSync([
		Buffer.from("token_owner", 'utf-8'),
		// enc.encode("token_owner"),
		mintKey.publicKey.toBuffer(),
	], PROGRAM);
	const ownerTokenAccount = getAssociatedTokenAddressSync(mintKey.publicKey, ownerPubkey, true);

	if (!mintKey.publicKey || !ownerPubkey) {
		throw "Logical error! Unable to find nonce, which is a non-sence...";
	} else {
		console.log("Found MINT ADDRESS!!!", mintKey.publicKey.toBase58());
		console.log("Found CURVE ADDRESS!!!", ownerPubkey.toBase58(), curveBump);
	}

	const name = tokenData.name;
	const symbol = tokenData.symbol;
	const url = tokenData.uri;
	const nameBuffer = Buffer.from(name, "utf-8");
	const symbolBuffer = Buffer.from(symbol, "utf-8");
	const urlBuffer = Buffer.from(url, "utf-8");

	const data = Buffer.alloc(1000);
	let offset = 0;

	// Selector = 1, create token
	data.writeUint8(1, offset); offset += 1;

	// Name, Symbol or URI can not be longer than 255
	data.writeUint8(curveBump, offset); offset += 1;
	data.writeUint8(urlBuffer.length, offset); offset += 1;
	data.write(url, offset, "utf-8"); offset += urlBuffer.length;
	data.writeUint8(nameBuffer.length, offset); offset += 1;
	data.write(name, offset, "utf-8"); offset += nameBuffer.length;
	data.writeUint8(symbolBuffer.length, offset); offset += 1;
	data.write(symbol, offset, "utf-8"); offset += symbolBuffer.length;

	console.log(data.slice(0, offset));

	const METAPLEX_PROGRAM = new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s");
	const [metaplex_account] = PublicKey.findProgramAddressSync( [ Buffer.from("metadata"), METAPLEX_PROGRAM.toBuffer(), mintKey.publicKey.toBuffer(), ],
		METAPLEX_PROGRAM);
	console.log("Metaplex account", metaplex_account.toBase58());

	const createTokenIx = new TransactionInstruction({
		data: data.slice(0, offset),
		programId: PROGRAM,
		keys: [
			{ pubkey: wallet.publicKey, isSigner: true, isWritable: true },
			{ pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
			{ pubkey: SYSTEM_PROGRAM, isSigner: false, isWritable: false },
			{ pubkey: ASSOCIATED_TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
			{ pubkey: mintKey.publicKey, isSigner: true, isWritable: true },
			{ pubkey: ownerPubkey, isSigner: false, isWritable: true },
			{ pubkey: ownerTokenAccount, isSigner: false, isWritable: true },
			{ pubkey: METAPLEX_PROGRAM, isSigner: false, isWritable: false },
			{ pubkey: metaplex_account, isSigner: false, isWritable: true },
		],
	});

	// add gas price via ComputeBudgetProgram.setUnitPrice

	const blockhash = await connection.getLatestBlockhash();
	const message = new TransactionMessage({
		payerKey: wallet.publicKey,
		recentBlockhash: blockhash.blockhash,
		instructions: [
			ComputeBudgetProgram.setComputeUnitLimit({ units: 150000 }),
			ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 1000 }),
			createTokenIx
		]
	}).compileToV0Message();

	const transaction = new VersionedTransaction(message);
	const extraSignature = sign(transaction.message.serialize(), mintKey.secretKey);
	transaction.addSignature(mintKey.publicKey, extraSignature);
	console.log('signing tx');
	const signedTransaction = await wallet.signTransaction(transaction);

	console.log('sending tx');
	const signature = await connection.sendTransaction(signedTransaction, {skipPreflight: true});
	console.log('tx sent, confirming...', signature);
	await waitTransaction(connection, signature);
	// await connection.confirmTransaction(signature);
	console.log(await connection.getTransaction(signature, { maxSupportedTransactionVersion: 0 }));
	return mintKey.publicKey;
}

export const buyToken = async (wallet: WalletContextState, orderData: BuyTokenData) => {
	if (!wallet.publicKey || !wallet.signTransaction) {
		throw new Error('Wallet PublicKey missed');
	}

	const [ownerPubkey, curveBump] = PublicKey.findProgramAddressSync([
		Buffer.from("token_owner", 'utf-8'),
		orderData.mint.toBuffer()
	], PROGRAM);
	const ownerTokenAccount = getAssociatedTokenAddressSync(orderData.mint, ownerPubkey, true);

	const data = Buffer.alloc(1000);
	let offset = 0;

	const userTokenAccount = getAssociatedTokenAddressSync(
		orderData.mint,
		wallet.publicKey,
		false
	);

	// TODO: check
	// @ts-ignore
	const createTokenAccountIx = new createAssociatedTokenAccountIdempotentInstruction(
		wallet.publicKey,
		userTokenAccount,
		wallet.publicKey,
		orderData.mint,
	);

	// Selector = 2, buy token
	data.writeUint8(2, offset); offset += 1;
	data.writeUint8(curveBump, offset); offset += 1;
	data.writeBigUInt64LE(orderData.solToSpend, offset); offset += 8;
	data.writeBigUInt64LE(orderData.minTokenToReceive, offset); offset += 8;

	const buyTokenIx = new TransactionInstruction({
		data: data.slice(0, offset),
		programId: PROGRAM,
		keys: [
			{ pubkey: wallet.publicKey, isSigner: true, isWritable: true },
			{ pubkey: SYSTEM_PROGRAM, isSigner: false, isWritable: false },
			{ pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
			{ pubkey: ownerPubkey, isSigner: false, isWritable: true },
			{ pubkey: orderData.mint, isSigner: false, isWritable: true },
			{ pubkey: ownerTokenAccount, isSigner: false, isWritable: true },
			{ pubkey: userTokenAccount, isSigner: false, isWritable: true },
			{ pubkey: FEE_ACCOUNT, isSigner: false, isWritable: true },
		],
	});
	// console.log('buyTokenIx',  buyTokenIx);
	// console.log('buyTokenIx.keys', buyTokenIx.keys.map(k => k.pubkey.toString()))

	const blockhash = await connection.getLatestBlockhash();
	const message = new TransactionMessage({
		payerKey: wallet.publicKey,
		recentBlockhash: blockhash.blockhash,
		instructions: [createTokenAccountIx, buyTokenIx]
	}).compileToV0Message();
	const transaction = new VersionedTransaction(message);
	// transaction.sign([wallet]);
	const signedTransaction = await wallet.signTransaction(transaction);

	const signature = await connection.sendTransaction(signedTransaction);
	console.log('tx sent, confirming...');
	// await connection.confirmTransaction(signature);
	await waitTransaction(connection, signature);
	console.log('tx confirmed');
	// console.log(await connection.getTransaction(signature, { maxSupportedTransactionVersion: 0 }));
}

async function waitTransaction(connection: Connection, signature: string) {
	if (isDev) {
		while (true) {
			const res = await connection.getTransaction(signature, {
				maxSupportedTransactionVersion: 0
			});
			if (res) {
				return res;
			}
		}
	}

	return await connection.confirmTransaction(signature);
}